import styled from 'styled-components'
import media from 'styled-media-query'
import { Link } from 'gatsby'

export const FooterLinksList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  ${media.lessThan('medium')`
    &:nth-of-type(1) {
      grid-column: 1/2;
      grid-row: 2;
    }

    &:nth-of-type(2) {
      grid-column: 1/2;
      grid-row: 3;
    }

    &:nth-of-type(3) {
      grid-column: 2/3;
      grid-row: 2/4;
    }
    color: ${props => props.theme.colors.secondary};

  `}
`

export const FooterLinksItems = styled.li`
  line-height: 2.4rem;
  margin-bottom: 2.2rem;

  ${media.between('medium', 'huge')`
    margin-bottom: 2rem;
  `}
`

export const FooterExternalLink = styled.a`
  font-family: 'Dasa Sans';
  font-size: 1.8rem;
  font-weight: normal;
  color: ${props => props.theme.colors.greyishBrown};
  text-decoration: none;

  ${media.between('medium', 'huge')`
    font-size: 1.4rem;
  `}

  ${media.lessThan('medium')`
    font-size: 1.6rem;
    color: ${props => props.theme.colors.secondary};
  `}
`

export const FooterInternalLink = styled(Link)`
  font-family: 'Dasa Sans';
  font-size: 1.8rem;
  font-weight: normal;
  color: ${props => props.theme.colors.greyishBrown};
  text-decoration: none;

  ${media.between('medium', 'huge')`
    font-size: 1.4rem;
  `}

  ${media.lessThan('medium')`
    font-size: 1.6rem;
    color: ${props => props.theme.colors.secondary};
  `}
`
