import styled from 'styled-components'
import media from 'styled-media-query'

export const MenuMobile = styled.ul`
  background-color: ${props => props.theme.colors.primary};
  position: relative;
  top: calc(0rem - 4.1rem);
  box-sizing: border-box;
  overflow: scroll;
  height: calc(100vh - 135px);
  margin: 0;
  padding: 0 2.4rem;
  z-index: 4;

  ${media.greaterThan('medium')`
    display: none;
  `}
`
