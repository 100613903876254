import styled from 'styled-components'

export const NavContainerLogo = styled.div`
  grid-area: burger;
  background-color: ${props => props.theme.colors.primary};
  height: 100%;
  width: 100%;
`
export const NavLink = styled.a`
  justify-content: right;
  align-items:center;
  display: flex;
  height: 100%;
  width: 140px;
`

export const ImgLogo = styled.img`
`
