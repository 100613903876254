import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'

const Social = ({
  socialsInfo: {
    instagram_url: instagram,
    facebook_url: facebook,
    linkedin_url: linkedin,
    youtube_url: youtube,
  },
}) => (
  <S.SocialLinksContainer>
    <S.SocialLinks href={instagram}>
      <S.InstagramIcon size={20} />
    </S.SocialLinks>
    <S.SocialLinks href={facebook}>
      <S.FacebookIcon size={20} />
    </S.SocialLinks>
    <S.SocialLinks href={linkedin}>
      <S.LinkedinIcon size={20} />
    </S.SocialLinks>
    <S.SocialLinks href={youtube}>
      <S.YoutubeIcon size={20} />
    </S.SocialLinks>
  </S.SocialLinksContainer>
)

Social.propTypes = {
  socialsInfo: PropTypes.objectOf(
    PropTypes.shape({
      instagram: PropTypes.string,
      facebook: PropTypes.string,
      linkedin: PropTypes.string,
      youtube: PropTypes.string,
    }).isRequired
  ).isRequired,
}

export default Social
