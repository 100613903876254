import styled from 'styled-components'
import media from 'styled-media-query'
import { Menu } from '@styled-icons/entypo/Menu'
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'

export const Main = styled.main`
  min-height: 4.2rem;
  display: grid;
  grid-template-columns: 3.5fr 2fr 5.5fr 2fr;
  gap: 0;
  grid-template-areas:
    'logo translate highlight menu'
    'buttons buttons buttons buttons';
  background-color: ${props => props.theme.colors.primary};
  padding: 1.2rem 2.4rem;

  ${media.greaterThan('medium')`
    display: none;
  `}

  @media (max-width: 767px) and (min-width: 600px) {
    grid-template-columns: 1.5fr 1fr 8.5fr 1fr;
  }

  @media only screen and (max-width: 320px) {
    grid-template-columns: 3fr 2fr 5fr 2fr;
  }
`

export const HeaderHighlightLink = styled.a`
  grid-area: highlight;
  justify-self: flex-end;
  align-self: center;
  font-size: 1.6rem;
  font-weight: bold;
  font-family: 'Dasa Sans';
  text-decoration: none;
  color: ${props => props.theme.colors.secondary};
`

export const MenuButton = styled.button`
  grid-area: menu;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
`

export const MenuIcon = styled(Menu)`
  color: ${props => props.theme.colors.secondary};
`

export const MenuCloseIcon = styled(CloseOutline)`
  color: ${props => props.theme.colors.secondary};
`
