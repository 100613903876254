import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// import { useTranslateContext } from 'gatsby-plugin-translate'

import { useHeaderContext } from '../../../Hooks/headerStates'
import MenuItem from './MenuItem'

import * as S from './styled'

const MenuContainer = () => {
  const data = useStaticQuery(graphql`
    query {
      Menu: allWordpressAltaThemeHeader {
        nodes {
          sidebars {
            widgets {
              value {
                name
                items {
                  title
                  url
                  wordpress_children {
                    title
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const menuMobileCheck = useHeaderContext()
  const menuOpenState = menuMobileCheck.getMenuMobileOpenState()

  // const { language } = useTranslateContext()

  const menuData = data.Menu.nodes[0].sidebars[0].widgets[1].value.items
  // tradução
  // const menuData =
  //   language === 'pt-br'
  //     ? data.Menu.nodes[0].sidebars[0].widgets[1].value.items
  //     : data.MenuEn.nodes[0].sidebars[0].widgets[1].value.items

  const hasPath = window.location.pathname

  useEffect(() => {
    const menuHidden = document.getElementById('menuMobile')

    if (menuOpenState === null) {
      menuHidden.classList.add('d-none')
    } else {
      menuHidden.classList.remove('d-none')
    }
  }, [hasPath])

  useEffect(() => {
    const menuHidden = document.getElementById('menuMobile')
    if (!menuOpenState) {
      setTimeout(() => {
        menuHidden.classList.add('d-none')
      }, 600)
    }
  })

  return (
    <S.MenuMobile
      id="menuMobile"
      className={menuOpenState ? 'open-animation' : 'close-animation'}
      open={menuOpenState}
    >
      <MenuItem menuData={menuData} />
    </S.MenuMobile>
  )
}

export default MenuContainer
