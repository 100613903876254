import styled from 'styled-components'
import { TranslateLink } from 'gatsby-plugin-translate'

export const DesktopMenuLinkHighlight = styled.a`
  font-size: 1.6rem;
  font-weight: bold;
  font-family: 'Dasa Sans';
  text-decoration: none;
  color: ${props => props.theme.colors.white};

  &:hover {
    opacity: 0.9;
  }
`

export const MenuItem = styled.li`
  position: relative;
  display: ${props => (props.visible ? 'none' : 'flex')};
  align-items: center;
  list-style-type: none;
  line-height: 2.6;
`

export const MenuLink = styled(TranslateLink)`
  display: block;
  width: 100%;
  color: ${props => props.theme.colors.white};
  font-size: 1.6rem;
  text-decoration: none;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
`
export const SubmenuTitle = styled.p`
  margin: 0;
  color: ${props => props.theme.colors.white};
  font-size: 1.6rem;
  font-family: 'Dasa Sans';
  cursor: pointer;
  opacity: ${props => (props.visible ? 1 : 0.8)};
`
