import { createGlobalStyle, keyframes } from 'styled-components'
import { DasaSansBold, DasaSansRegular } from '../utils/fonts'

const FadeIn = keyframes`{
  from {
    opacity: 0;
    visibility: visible;
    display: block;
  }
  to {
    opacity: 1;
  }
}`

const FadeOut = keyframes`{
  from {
   opacity: 1;
  }
  to {
    opacity: 0;
    visibility: hidden;
    display: none;
  }
}`

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: "Dasa Sans";
    src: local("Dasa Sans Bold"), url(${DasaSansBold}) format('truetype');
    font-weight: bold;
  }

  @font-face {
    font-family: "Dasa Sans";
    src: local("Dasa Sans Regular"), url(${DasaSansRegular}) format('truetype');
    font-weight: normal;
  }

  html {
    font-size: 10px;
    font-family: 'Dasa Sans';
    body {
      margin: 0;
      padding: 0;
    }

    a, h1, h2, h3, h4, h5, h6, .accordion-title {
      font-family: 'Dasa Sans'
    }
  }

  /* svg colors */
  .primary-fill-color {
    fill: ${props => props.theme.colors.secondary} !important;
  }

  /* block main scroll */
  .scroll {
    overflow-y: scroll;
    height: 100vh;
  }

  .block-scroll {
    overflow-y: hidden;
    max-height: 100vh;
  }

  /* mobile animation */
  .open-animation {
    animation: .5s ${FadeIn} forwards;
  }

  .close-animation {
    animation: .5s ${FadeOut} forwards;
  }

  .d-none {
    display: none;
  }
`

export default GlobalStyle
