import React, { useState, useCallback, useEffect } from 'react'
import AllProductService from './service'
import { useHeaderContext } from '../../Hooks/headerStates'
import { isMobile, debounce } from '@cms/utils'

import SearchDesktop from './SearchDesktop'
import SearchMobile from './SearchMobile'

const Search = () => {
  const headerContext = useHeaderContext()
  const openDesktopState = headerContext.getSearchOpenState()
  const openMobileState = headerContext.getMenuMobileOpenState()

  const [query, setQuery] = useState('')
  const [loading, setLoading] = useState(false)
  const [searchResult, setSearchResult] = useState([])
  const [notSearchResult, setNotSearchResult] = useState(false)

  const screen = typeof window !== `undefined` ? window.screen : false
  const [mobileDevice, setMobileDevice] = useState(false)

  useEffect(() => {
    if (isMobile(screen)) {
      setMobileDevice(true)
    }
  }, [screen])

  const onSearch = term => {
    if (term === '') return
    setLoading(true)
    setNotSearchResult(false)

    AllProductService.search(term).then(data => {
      if (term.length > 0 && data.length === 0) {
        setNotSearchResult(true)
        return setSearchResult(data)
      }
      if (data.length > 3) {
        const slice = data.slice(0, 4)
        setLoading(false)
        return setSearchResult(slice)
      }
      setLoading(false)
      return setSearchResult(data)
    })
  }

  const debouncedSearch = useCallback(debounce(onSearch, 500), [])

  const inputChange = e => {
    const term = e.target.value
    setQuery(term)
    debouncedSearch(term)
  }

  const handleSubmit = e => {
    e.preventDefault()
    document.querySelector('#search-query').blur()
    onSearch(query)
  }

  const openDesktop = () => {
    headerContext.setSearchOpenState(isChecked => !isChecked)
    clearSearch()
  }

  const clearSearch = () => {
    setQuery('')
    onSearch('')
  }

  useEffect(() => {
    const altaBody = document.getElementsByTagName('BODY')[0]
    const altaHtml = document.getElementsByTagName('HTML')[0]

    if (openDesktopState) {
      altaHtml.classList.add('scroll')
      altaBody.classList.add('block-scroll')
    } else {
      altaHtml.classList.remove('scroll')
      altaBody.classList.remove('block-scroll')
    }
  }, [openDesktopState])

  return (
    <>
      {mobileDevice ? (
        <SearchMobile
          openMobileState={openMobileState}
          handleSubmit={handleSubmit}
          query={query}
          inputChange={inputChange}
          clearSearch={clearSearch}
          notSearchResult={notSearchResult}
          searchResult={searchResult}
          loading={loading}
        />
      ) : (
        <SearchDesktop
          openDesktopState={openDesktopState}
          openDesktop={openDesktop}
          handleSubmit={handleSubmit}
          query={query}
          inputChange={inputChange}
          clearSearch={clearSearch}
          notSearchResult={notSearchResult}
          searchResult={searchResult}
          loading={loading}
        />
      )}
    </>
  )
}

export default Search
