import styled from 'styled-components'
import media from 'styled-media-query'

export const Main = styled.main`
  min-height: 4.3rem;
  display: grid;
  /* gap: 0 1.5rem; */
  grid-template-areas: 'logo search buttons nav';
  /* grid-template-areas: 'logo search buttons translate'; */
  background-color: ${props => props.theme.colors.primary};
  padding: 1.6rem 3.8rem;

  /* acrescentar uma coluna com 0.5fr no final para o grid-area: translate */
  ${media.greaterThan('huge')`
    grid-template-columns: ${props => (props.searchOpen ? `1fr 8fr 3fr` : `1fr 7.5fr auto 1fr`)};
  `}

  ${media.between('large', 'huge')`
    grid-template-columns: ${props => (props.searchOpen ? `1fr 8fr 3fr` : `1fr 6fr auto 1fr`)};
  `}

  ${media.between('medium', 'large')`
    grid-template-columns: ${props => (props.searchOpen ? `1fr 10fr 1fr` : `1fr 3fr auto 1fr`)};
  `}

  @media only screen and (max-width: 767px) {
    display: none;
  }
`

export const ContainerNavLogo = styled.div`
  display: none;

  ${media.greaterThan('medium')`
    margin-left: auto;
    align-items:center;
    max-width: 140px;
    display: flex;
    justify-content: center;
  `}
`
