import styled from 'styled-components'
import media from 'styled-media-query'
import { Container } from '../shared/container'

export const FooterSection = styled.div`
  padding: 6.4rem 0 0;
  background-color: ${props => props.theme.colors.primary};
  width: 100%;

  ${media.lessThan('medium')`
    padding: 3.2rem 0 0;
    /* background-color: ${props => props.theme.colors.secondary}; */
    color: ${props => props.theme.colors.secondary};
  `}
`

export const FooterContainer = styled(Container)`
  ${media.between('medium', 'large')`
    margin: 0 4.8rem;
  `}
`

export const FooterGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0 7.2rem;

  ${media.lessThan('large')`
    grid-template-columns: repeat(2, 1fr);
    gap: 0 4.8rem;
  `}
  ${media.lessThan('medium')`
    color: ${props => props.theme.colors.white};
  `}
`

export const ALtaFooterLogo = styled.img`
  ${media.lessThan('medium')`
    grid-column: 1/3;
    grid-row: 1;
    margin-bottom: 3.6rem;
  `}
`

export const TechnicalManager = styled.p`
  grid-column: 2/6;
  font-size: 1.6rem;
  color: ${props => props.theme.colors.secondary};
  font-weight: 600;
  margin: 4rem 0;

  ${media.between('medium', 'huge')`
    font-size: 1.4rem;
    margin: 2.4rem 0;
  `}

  ${media.lessThan('medium')`
    grid-column: 1/3;
    grid-row: 7;
    color: ${props => props.theme.colors.secondary};
  `}
`
