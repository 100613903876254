import styled from 'styled-components'
import media from 'styled-media-query'
import { TranslateLink } from 'gatsby-plugin-translate'
import { KeyboardArrowRight } from '@styled-icons/material-rounded'

export const MenuItem = styled.li`
  position: relative;
  display: ${props => (props.visible ? 'none' : 'flex')};
  align-items: center;
  list-style-type: none;
  line-height: 2.6;

  ${media.lessThan('medium')`
    position: static;

    &:last-of-type {
      margin-bottom: 8rem;
    }

    &:nth-child( -n + 4 ) {
      > a, > p {
        font-size: 2rem;
      }
    }
  `}
`

export const MenuLink = styled(TranslateLink)`
  display: block;
  width: 100%;
  color: ${props => props.theme.colors.secondary};
  font-size: 1.6rem;
  text-decoration: none;
`
export const SubmenuTitle = styled.p`
  margin: 0;
  color: ${props => props.theme.colors.secondary};
  font-size: 1.6rem;
  font-family: 'Dasa Sans';
  cursor: pointer;
`

export const ArrowRightIcon = styled(KeyboardArrowRight)`
  color: ${props => props.theme.colors.secondary};
  cursor: pointer;
`
