import React, { useState, useEffect } from 'react'
import { HeaderProvider } from './Hooks/headerStates'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'
import { isMobile } from '@cms/utils'

import * as S from './styled'

const Header = () => {
  const screen = typeof window !== `undefined` ? window.screen : false
  const [mobileDevice, setMobileDevice] = useState(false)

  useEffect(() => {
    if (isMobile(screen)) {
      setMobileDevice(true)
    }
  }, [screen])

  return (
    <HeaderProvider>
      <S.Header>{mobileDevice ? <HeaderMobile /> : <HeaderDesktop />}</S.Header>
    </HeaderProvider>
  )
}

export default Header
