import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslations } from 'gatsby-plugin-translate'
import { isMobile } from '@cms/utils'

import * as S from './styled'

const SchedulingButton = ({ schedulelLinkSp, schedulelLinkRj, schedulelLinkDf }) => {
  const t = useTranslations()
  const translatedOnlineSchedule = t`Agendar`

  const [optionsVisible, setOptionsVisible] = useState(false)

  useEffect(() => {
    const handleContentToggle = event => {
      if (event.target.id !== 'schedulingOptions') {
        setOptionsVisible(false)
        return
      }
      setOptionsVisible(isVisible => !isVisible)
    }
    window.addEventListener('click', handleContentToggle)

    return () => {
      window.removeEventListener('click', handleContentToggle)
    }
  }, [])

  const [mobileDevice, setMobileDevice] = useState(false)

  const screen = typeof window !== `undefined` ? window.screen : false

  useEffect(() => {
    if (isMobile(screen)) {
      setMobileDevice(true)
    }
  }, [screen])

  const isExternalLink = url => {
    return url.match(/^https?:\/\//g)
  }

  const handleTag = location => {
    if (window && window.dataLayer)
      window.dataLayer.push({
        event: 'ga_event',
        eventCategory: 'clicou_btn',
        eventAction: 'Agendamento online',
        eventLabel: location,
      })
  }

  const dataScheduling = [
    {
      label: 'São Paulo',
      link: schedulelLinkSp,
    },
    {
      label: 'Rio de Janeiro',
      link: schedulelLinkRj,
    },
    {
      label: 'Distrito Federal',
      link: schedulelLinkDf,
    },
  ]

  return (
    <S.Label
      onMouseEnter={mobileDevice ? null : () => setOptionsVisible(true)}
      onMouseLeave={mobileDevice ? null : () => setOptionsVisible(false)}
      visible={optionsVisible}
      id="schedulingOptions"
    >
      {translatedOnlineSchedule}
      <S.ArrowDownIcon size={20} />
      <S.LinksWrapper visible={optionsVisible}>
        {dataScheduling.map(region => (
          <S.Link
            key={region.label}
            onClick={() => handleTag(region.label)}
            href={region.link}
            target={isExternalLink(region.link) ? '_blank' : ''}
          >
            {region.label}
          </S.Link>
        ))}
      </S.LinksWrapper>
    </S.Label>
  )
}

SchedulingButton.propTypes = {
  schedulelLinkSp: PropTypes.string.isRequired,
  schedulelLinkRj: PropTypes.string.isRequired,
}

export default SchedulingButton
